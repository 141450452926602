$(window).on('scroll', () => {
  if ($(document).scrollTop() >= 390) {
    $('.navbar').addClass('activate');
  } else {
    $('.navbar').removeClass('activate');
  }
});

$(window).on('load', function () {
  'use strict';
  $('#preloader')
    .delay(350)
    .fadeOut('slow');
  $('body')
    .delay(350)
    .css({
      overflow: 'visible'
    });
  if (window.location.hash != '') {
    var offsetPage = -70;
    $('html, body').animate({
        scrollTop: $(window.location.hash).offset().top + offsetPage
      },
      800,
      'easeInOutExpo'
    );
  }
});

new WOW().init();
$(document).ready(() => {
  $('.filter-btn').click(function (e) {
    var filterName = $(e.target).data('filter')
    $(this).toggleClass('active')
    if ($(this).hasClass('active')) {
      $('.bike-box.' + filterName + ':not(.active)').removeClass('hider')
      $('.bike-box.' + filterName + ':not(.active)').delay(1).fadeIn()
    } else {
      $('.bike-box.' + filterName + ':not(.active)').addClass('hider')
      $('.bike-box.' + filterName + ':not(.active)').delay(1).fadeOut()
    }

  });
  $('.bike-box input').on('change', function (e) {
    if ($(e.target).closest('.bike-box').find('input:checked').length > 0) {
      $(e.target).closest('.bike-box').addClass('active')
    } else {
      $(e.target).closest('.bike-box').removeClass('active')
    }
  })

  $('[data-filter]').on('click', (e) => {
    var filterName = $(e.target).data('filter')
    $('.filtr-item').each((index, val) => {
      if ($(val).hasClass(filterName)) {
        $('.filtr-item.' + filterName + '').removeClass('hider')
        $('.filtr-item.' + filterName + '').delay(1).fadeIn()
        $('.filtr-item:not(.' + filterName + ')').addClass('hider')
        $('.filtr-item:not(.' + filterName + ')').delay(1).fadeOut()
      }
    })
  })
  if ($('#filterbar').length > 0) {
    var waypoint = new Waypoint({
      element: document.getElementById('filterbar'),
      offset: '80px',
      handler: function (direction) {
        $('#filterbar').toggleClass('fixed', direction === 'down');
        $('#hotele').toggleClass('pt120', direction === 'down');
      }
    });
  }
  $('.number-spinner').click((e) => {
    $(e.target).next().val(parseInt($(e.target).next().val()) + 1)
  })
  $('.number-spinner-down').click((e) => {
    if ($(e.currentTarget).hasClass('old')) {
      if ($(e.target).prev().val() > 1) {
        $(e.target).prev().val(parseInt($(e.target).prev().val()) - 1)
      }
    } else {
      if ($(e.target).prev().val() > 0) {
        $(e.target).prev().val(parseInt($(e.target).prev().val()) - 1)
      }

    }
  });
  if ($('#datetimepicker').length > 0) {
    $('#datetimepicker').datetimepicker({
      locale: 'pl',
      minDate: new Date(),
      keepOpen: true,
      format: 'D/MM/YYYY'
    });
    $('#datetimepicker2').datetimepicker({
      locale: 'pl',
      minDate: new Date(),
      keepOpen: true,
      format: 'D/MM/YYYY'
    });
  }
  $('.input-group > input').on('focus', function () {
    $(this).next().trigger('click')
  });

  if ($.cookie('cookielaw')) {
    $('#cookie').hide();
  } else {
    $('#cookie').show();
  }

  $('.close-cookie').click(function (e) {
    e.preventDefault();
    $.cookie('cookielaw', 'true', {
      expires: 360,
      path: '/'
    });
    $('#cookie').fadeOut('slow');
  });

  $('.cta-smooth').on('click', function (e) {
    e.preventDefault();
    var clickedObj = $(this).attr('href'),
      offsetPage = -70;
    $('html, body').animate({
        scrollTop: $(this.hash).offset().top + offsetPage
      },
      800,
      'easeInOutExpo'
    );
  });

  $('[data-toggle="tooltip"]').tooltip();

  jQuery.validator.addMethod(
    'noSpace',
    function (value, element) {
      return value === '' || value.trim().length !== 0;
    },
    'Wpisz poprawną wartość'
  );

  $('#recaptcha-error').hide();

  $('#contact-form')
    .submit(function (e) {
      e.preventDefault();
    })
    .validate({
      ignore: '.ignore',
      submitHandler: function (form) {
        $.ajax({
          type: 'POST',
          url: 'https://hotelzrowerem.pl/wp-content/themes/hzr-sare/mail/contact_me_modal.php',
          data: $('#contact-form').serialize(),
          success: function (msg) {
            console.log(msg);
            if (msg === 'ok' || msg === 'okok') {
              $('button[type=submit]').hide();
              dataLayer.push({
                'event': 'VirtualPageview',
                'virtualPageURL': '/formularz-kontakt-HZR',
                'virtualPageTitle': 'Formularz kontaktowy - podziękowanie'
              });
              $('#contact-form').html('Dziękujemy, Twoje zapytanie zostało wysłane. Postaramy się skontaktować z Państwem w ciągu 24 godzin.');
              $('.error-wrap').hide();
            } else {
              $('button[type=submit]').show();
              $('#contact-form').after(
                '<span class="error-wrap">' + msg + '</span>'
              );
            }
          }
        });
        return false;
      },
      errorPlacement: function (error, element) {
        error.appendTo(element.parent('div'));
      },
      highlight: function (element) {
        $(element)
          .parent()
          .addClass('animated shake invalid');
        $(element).addClass('invalid');
      },
      unhighlight: function (element) {
        $(element)
          .parent()
          .removeClass('animated shake invalid');
        $(element).removeClass('invalid');
      },
      focusInvalid: true,
      onfocusout: false,
      focusCleanup: false,
      rules: {
        s_property2: {
          noSpace: true
        },
        s_property3: {
          noSpace: true
        },
        s_property4: {
          required: true,
          noSpace: true
        },
        s_gsm: {
          noSpace: true
        },
        zgoda: 'required',
        s_email: {
          required: true,
          email: true,
          noSpace: true
        },
        hiddenRecaptcha: {
          required: function () {
            if (grecaptcha.getResponse() === '') {
              return true;
            } else {
              return false;
            }
          }
        }
      },
      messages: {
        s_property4: 'Proszę wypełnić to pole',
        zgoda: 'Proszę zaznaczyć zgodę',
        s_email: {
          required: 'Proszę wypełnić pole e-mail',
          email: 'Podaj właściwy adres e-mail'
        },
        hiddenRecaptcha: 'Potwierdź, że nie jesteś robotem.'
      }
    });
  $('#hotel-form')
    .submit(function (e) {
      e.preventDefault();
    })
    .validate({
      ignore: '.ignore',
      submitHandler: function (form) {
        let bikeResponse = '';
        console.log('$("input.bike-input:checked").length: ', $('input.bike-input:checked').length);
        if ($('input.bike-input:checked').length > 0) {
          $('input.bike-input:checked').each((index, element) => {
            console.log('index: ', index, element);
            bikeResponse += $(element).closest('.bike-box').find('h4').text() + ' - rozmiar: ' + element.value + ', <br>';
          });
          console.log('bikeResponse: ', bikeResponse);

          $('#rowery').val(bikeResponse);

        } else {
          alert('Wybierz rower/y');
          return false;
        }

        $.ajax({
          type: 'POST',
          url: 'https://hotelzrowerem.pl/wp-content/themes/hzr-sare/send.php',
          data: $('#hotel-form').serialize(),
          success: function (msg) {
            console.log(msg);
            if (msg === 'ok') {
              $('button[type=submit]').hide();
              dataLayer.push({
                'event': 'VirtualPageview',
                'virtualPageURL': '/formularz-hotel-rezerwacja',
                'virtualPageTitle': 'Formularz rezerwacyjny'
              });
              $('#hotel-form').html('Dziękujemy, Twoje zapytanie zostało wysłane. Postaramy się skontaktować z Państwem w ciągu 24 godzin.');
              $('.error-wrap').hide();
            } else {
              $('button[type=submit]').show();
              $('#hotel-form').after(
                '<span class="error-wrap">' + msg + '</span>'
              );
            }
          }
        });
        return false;
      },
      errorPlacement: function (error, element) {
        error.appendTo(element.closest('.form-group'));
      },
      highlight: function (element) {
        $(element)
          .parent()
          .addClass('animated shake invalid');
        $(element).addClass('invalid');
      },
      unhighlight: function (element) {
        $(element)
          .parent()
          .removeClass('animated shake invalid');
        $(element).removeClass('invalid');
      },
      focusInvalid: true,
      onfocusout: false,
      focusCleanup: true,
      rules: {
        od: {
          required: true
        },
        do: {
          required: true
        },
        s_gsm: {
          require_from_group: [1, '.contact-group'],
          noSpace: true
        },
        zgoda: 'required',
        s_email: {
          require_from_group: [1, '.contact-group'],
          email: true,
          noSpace: true
        },
        hiddenRecaptcha: {
          required: function () {
            if (grecaptcha.getResponse() === '') {
              return true;
            } else {
              return false;
            }
          }
        }
      },
      messages: {
        od: 'Proszę wypełnić to pole',
        do: 'Proszę wypełnić to pole',
        zgoda: 'Proszę zaznaczyć zgodę',
        s_gsm: {
          require_from_group: 'Proszę wypełnić pole telefon lub e-mail',
        },
        s_email: {
          require_from_group: 'Proszę wypełnić pole telefon lub e-mail',
          email: 'Podaj właściwy adres e-mail'
        },
        hiddenRecaptcha: 'Potwierdź, że nie jesteś robotem.'
      }
    });



})
